import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../css/BurguerIcon.css'

export default class BurguerIcon extends Component {
  	render() {
		return(<span className="burger-icon"></span>);
	}
}

BurguerIcon.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string
}

BurguerIcon.defaultProps = {
	width: 25,
	height: 25,
	color: 'red'
}