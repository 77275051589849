import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const Container = (props) => (
  <div className={css(styles.Container)}>
    {props.children}
  </div>
);

const styles = StyleSheet.create({
  Container: {
    display: 'block',
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto'
  }
});

export default Container;