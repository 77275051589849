import React, { Component } from 'react';

import Container from '../components/Container';
import {Helmet} from "react-helmet";

class Instructions extends Component {
	render() {
    return (
      <div className="accessories">
      	<Helmet title="Rowenta | Instructions for use" />
				<section id="main" className="page">
					<Container>
						<img style={{width: '100%', height: '275px'}} src="https://www.rowenta.com/medias/Rowenta-bannieres-suite-V1R6-instructions-for-use.jpg?context=bWFzdGVyfGltYWdlc3wxNjUwOHxpbWFnZS9qcGVnfGltYWdlcy9oYjkvaDdmLzg5NDIyNzMwMDM1NTAuanBnfGFmNDExZjRjYjYzMzk4NjFmNzFjYWExZTRlNzhlOTNlMDJiOTVkZDcwZjRhZTY0YzBmODJlNTQ3NmE3YWM3Nzg" alt="Instructions for use" />
						<h1>For a full comprehensive list of Rowenta products please use our international site.</h1>
						<a style={{marginBottom: 50, display: 'block'}} href="https://www.rowenta.com/instructions-for-use">Click here to open the rowenta.com site.</a>
					</Container>
				</section>
      </div>
    );
  }
}

export default Instructions;