import React, { Component } from 'react';
import SectionTitle from '../components/SectionTitle';
import Container from '../components/Container';

export default class NotFound extends Component {
  render() {
    return (

        <div className="NotFound">
          <Container>
            <SectionTitle topTitle="Oops!" bottomTitle="Error 404" color="#A4B0B8"/>
            <h2 style={{textAlign: 'center'}}>Page not found</h2>
          </Container>
        </div>

    );
  }
}
