import React from 'react';
import '../css/Products.css';
import Container from '../components/Container';

const Products = (props) => {
  return (
    <Container>
      <div className="products">
        {props.children}
      </div>
    </Container>
  );
};

export default Products;