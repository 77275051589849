import React from 'react';
import Hero from '../components/Hero';
import SubNav from '../components/SubNav';
import FeaturedProducts from '../components/FeaturedProducts';

const Home = (props) => {
  return (
    <div className="Home">
      <Hero />
      <SubNav />
      <FeaturedProducts location={props.location}/>
    </div>
  );
}

export default Home;