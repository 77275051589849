import React from 'react';
import CategoryBanner from '../components/CategoryBanner';
import Nav from '../components/Nav';
import FeaturedProducts from '../components/FeaturedProducts';

const Category = (props) => {
  return (
    <div className="Category">
      <Nav title={props.title}/>
      <CategoryBanner bg={props.bg} title={props.title} desc={props.desc} prodLink={props.prodLink} />
      <FeaturedProducts location={props.location}/>
    </div>
  );
}

export default Category;