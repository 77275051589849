import React from 'react';
import '../css/CategoryBanner.css';
import { Link } from 'react-router-dom';

const CategoryBanner = (props) => {
	return(
		<section className="TopBanner" style={{background: `linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url(${props.bg})`}}>
			<div className="TopBannerContent">
				<h1 className="TopBannerTitle">
					{props.title}
				</h1>
				<p className="TopBannerDesc">{props.desc}{props.product}</p>
				<Link className="btn" to={props.prodLink} style={{fontSize: 16}}>
					Discover the product
				</Link>
			</div>
		</section>
	);
}

export default CategoryBanner;