import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Container from '../components/Container';
import {Helmet} from "react-helmet";


import '../css/About.css';
import AboutUsInventor from '../images/aboutus-inventor.jpg';
import AboutUsSebgroup from '../images/aboutus-sebgroup.jpg';

const About = () => {
  return (
	    <div className="About">
	    	<Helmet title="Rowenta | About us"  ></Helmet>
	    	<Breadcrumb />
	    	<div className="AboutUsBanner">
	    		<h1>About us</h1>
	    	</div>
		 	<Container>
		        <div className="AboutRowenta">
		        	<h2>About Rowenta</h2>
		        	<p className="AboutRowentaIntroLead">Since 1909, German brand Rowenta has captivated the most demanding consumers with high-quality products that guarantee perfect results.</p>
		        	<p>Rowenta offers a wide range of products that combine unrivalled technological performance, refined design, and a high level of user-friendliness.</p>
		        	<p>Its mission is to ensure the day-to-day well-being of its users thanks to intelligent solutions for vacuum cleaners, irons, steam generators and hair dryers. Ever the pioneer, Rowenta has worked for more than 10 years on developing eco-intelligent products. Faithful to its own values, the Rowenta brand has placed eco-design at the forefront of its innovation process.</p>
			        <div className="AboutRowentaContent">
			        	<div className="AboutRowentaInnovators">
			        		<h3>Innovators</h3>
			        		<img src={AboutUsInventor} />
			        		<div className="AboutRowentaConentTxt">
				        		<p className="AboutRowentaContentLead">Every year, Groupe SEB launches more than 250 new products.</p>
				        		<p>This innovation rate is made possible thanks to our Research and Development teams but also to our "open innovation" approach. Go to our special website if you wish to join this community.</p>
				        		<a href="http://www.innovate-with-groupeseb.com/">http://www.innovate-with-groupeseb.com</a>
				        	</div>
				        </div>
			          	<div className="AboutRowentaSebgroup">
			          		<h3>Groupe SEB</h3>
			          		<img src={AboutUsSebgroup} />
			          		<div className="AboutRowentaConentTxt">
				          		<p className="AboutRowentaContentLead">Discover Groupe SEB, world leader in small domestic appliances.</p>
				          		<p>Active in nearly 150 countries, Groupe SEB has become a market leader on all continents thanks to its wide and highly diverse range of product and its outstanding portfolio of brand names.</p>
				          		<a href="http://www.groupeseb.com/en-en">http://www.groupeseb.com/en-en</a>
				          	</div>
			        	</div>

			        </div>

		      	</div>
			</Container>
	    </div>

  );
};

export default About;








