import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { StyleSheet, css } from 'aphrodite'

export default class SearchIcon extends Component {
  	render() {
		return( 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.471 587.471" width={this.props.width} height={this.props.height}>
			  <path fill={this.props.color} width={this.props.width} height={this.props.height} d="M220.302 440.604c121.476 0 220.302-98.826 220.302-220.302S341.777 0 220.302 0C98.826 0 0 98.826 0 220.302c0 121.475 98.826 220.302 220.302 220.302zm0-369.462c82.247 0 149.16 66.913 149.16 149.16S302.548 369.46 220.3 369.46 71.142 302.548 71.142 220.3s66.913-149.16 149.16-149.16z"/>
			  <path fill={this.props.color} width={this.props.width} height={this.props.height} d="M525.523 587.47c16.555 0 32.113-6.446 43.8-18.157 11.7-11.68 18.147-27.234 18.147-43.79 0-16.554-6.447-32.116-18.152-43.823L446.643 359.023c-3.262-3.262-7.475-5.06-11.86-5.06-5.448 0-10.464 2.71-13.76 7.437-16.24 23.318-36.298 43.377-59.614 59.615-4.26 2.965-6.95 7.467-7.38 12.352-.43 4.828 1.39 9.666 4.995 13.27L481.7 569.313c11.705 11.71 27.268 18.158 43.82 18.158z"/>
			</svg>
		
	    )
	}
}

SearchIcon.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string
}

SearchIcon.defaultProps = {
	width: 25,
	height: 25,
	color: 'red'
}