import React from 'react';
import '../css/ProductPreview.css';
import { Link } from 'react-router-dom';

const ProductPreview = (props) => {
  return (
    <Link className={props.category === 'linen-care' ? 'SlideItem SlideItem-LinenCare' : 'SlideItem SlideItem-HomeComfort'} title={props.title} to={{ pathname: props.permalink, state: { product: props.product } }}>
      <div className="SlideItem_Img">
        <img className="picture" src={props.image} alt={props.title}/>
      </div>
      
      <div className="SlideItem_Caption">
        <p className="SlideItem_Title">{props.title}</p>
        <p className="SliderItem_Description">{props.desc}</p>
      </div>
    </Link>
  );
};

export default ProductPreview;