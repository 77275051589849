import React 						from 'react';
import {Helmet} 				from "react-helmet";

import Breadcrumb 			from '../components/Breadcrumb';
import TopBannerSimple 	from '../components/TopBannerSimple';
import Container 				from '../components/Container';

import '../css/Guarantee.css';

const Guarantee = () => {

  return (

		<div className="Guarantee">
		  <Helmet title="Rowenta | Guarantee"></Helmet>
	    <Breadcrumb />
	    <TopBannerSimple />
			<Boxes />
		</div>
  );
}

const Boxes = () => {

	return(
  	<section className="Guarantee">
	    <Container>
				<div className="GuaranteeBlock">
					<div className="item">
						<h2><strong>Standard Electrical appliances</strong></h2>
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<ul>
							<li>
								<a href="/guarantee-files/SDA.pdf" target="_blank">Download the information about the guarantee</a>
							</li>
							<li>
								<a href="/guarantee-files/SDACountryList.pdf" target="_blank">Download the country list</a>
							</li>
						</ul>
					</div>
					<div className="GuaranteeBottom">
						<p>Didn't find the information you need?</p>
						<a href="/consumer-services/contact-us">Contact us</a>
					</div>
				</div>

				<div className="GuaranteeBlock">
					<div className="item">
						<h2><strong>Non electrical ironing board</strong></h2>
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<ul>
							<li>
								<a href="/guarantee-files/NonElectrical.pdf" target="_blank">Download the information about the guarantee</a>
							</li>
							<li>
								<a href="/guarantee-files/NonElectricalCOUNTRYLIST.pdf" target="_blank">Download the country list</a>
							</li>
						</ul>
					</div>
					<div className="GuaranteeBottom">
						<p>Didn't find the information you need?</p>
						<a href="/consumer-services/contact-us">Contact us</a>
					</div>
				</div>

				<div className="GuaranteeBlock">
					<div className="item">
						<h2><strong>Accessories</strong></h2>
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<ul>
							<li>
								<a href="/guarantee-files/Accessories.pdf" target="_blank">Download the information about the guarantee</a>
							</li>
							<li>
								<a href="/guarantee-files/ACCESSORIESCOUNTRYLIST.pdf" target="_blank">Download the country list</a>
							</li>
						</ul>
					</div>
					<div className="GuaranteeBottom">
						<p>Didn't find the information you need?</p>
						<a href="/consumer-services/contact-us">Contact us</a>
					</div>
				</div>

				<div className="GuaranteeBlock">
					<div className="item">
						<h2><strong>Steampod Professional</strong></h2>
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<ul>
							<li>
								<a href="/guarantee-files/Pro.pdf" target="_blank">Download the information about the guarantee</a>
							</li>
							<li>
								<a href="/guarantee-files/PROCtryList.pdf" target="_blank">Download the country list</a>
							</li>
						</ul>
					</div>
					<div className="GuaranteeBottom">
						<p>Didn't find the information you need?</p>
						<a href="/consumer-services/contact-us">Contact us</a>
					</div>
				</div>

				<div className="GuaranteeBlock">
					<div className="item">
						<h2><strong>Steampod Resale</strong></h2>
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<ul>
							<li>
								<a href="/guarantee-files/Resale.pdf" target="_blank">Download the information about the guarantee</a>
							</li>
							<li>
								<a href="/guarantee-files/RESALECOUNTRYLIST.pdf" target="_blank">Download the country list</a>
							</li>
						</ul>
					</div>
					<div className="GuaranteeBottom">
						<p>Didn't find the information you need?</p>
						<a href="/consumer-services/contact-us">Contact us</a>
					</div>
				</div>
			</Container>
		</section>
	);
 }

export default Guarantee;

