import React, { Component } from 'react';
import Container from '../components/Container';
import '../css/Repair.css';

class RepairCentres extends Component {
	render() {
		return (
			<Container>
				<h1>REPAIRS</h1>
				<p>Wherever you are Rowenta is at your service either to repair a product or supply accessories and spare parts.</p>

				<div className="repair-centre-section">
					<h3>WITHIN THE MANUACTURER’S GUARANTEE PERIOD</h3>
					<p>If your item is within the guarantee period, please contact our Customer Relations Team first either via the Helpline on: 0345 602 1454  (UK) or 01 677 4003 (Republic of Ireland) or by email via <a href="/consumer-services/contact-us">Contact Us</a></p>
					<p>NOTE: If you do not contact our Customer Relations Team first your product will be treated as out of guarantee and any repairs or service will be chargeable.</p>
				</div>

				<div className="repair-centre-section">
					<h3>OUTSIDE OF THE MANUFACTURER’S GUARANTEE PERIOD</h3>
					<p>If your item is out of guarantee please contact our authorised service agent directly to arrange a repair via the details below:</p>

					<div className="repair-centre">
						<h2>UNITED KINGDOM</h2>
						<p><strong>Staffords Ltd</strong></p>
						<p>Overbrook Court, Overbrook Lane, Knowsley Business Park</p>
						<p>Liverpool, L34 9FB</p>
						<p>Email address: <a href="mailto:callcentre@staffords.ltd.uk">callcentre@staffords.ltd.uk</a></p>
						<p>Tel: 0345 758 1545</p>
					</div>

					<div className="repair-centre">
						<h2>CHANNEL ISLANDS</h2>
						<p><strong>Nigel Crespel Services</strong></p>
						<p>2 Jardin Du Mont A l'Abbe</p>
						<p>La Grande Route De St. Jean</p>
						<p>St. Helier</p>
						<p>Jersey, JE2 3FN</p>
						<p>Email address: <a href="mailto:ncrespel@googlemail.com">ncrespel@googlemail.com</a></p>
					</div>

					<div className="repair-centre">
						<h2>REPUBLIC OF IRELAND</h2>
						<p><strong>Groupe SEB Service Dublin </strong></p>
						<p>Telro Ltd, Unit B3 Aerodrome Business Park</p>
						<p>Rathcoole, CO. Dublin, Ireland</p>
						<p>Email address: <a href="mailto:repairsdublin@groupeseb.ie">repairsdublin@groupeseb.ie</a></p>
						<p>Tel: (01) 401 8448</p>

						<hr/>

						<p><strong>TVAE Cork</strong></p>
						<p>7B Airways Technology Park, Ballygarvan</p>
						<p>Cork, Ireland</p>
						<p>Email address: <a href="mailto:info@tvae.ie">info@tvae.ie</a></p>
						<p>Tel: (021) 4849160</p>

					</div>


				</div>




			</Container>
		);
	}
}

export default RepairCentres;